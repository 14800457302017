import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDashboardViews(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('user/dashboard-views', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDashboardView(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`user/dashboard-views/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
