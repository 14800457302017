<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Departments"
      @click.native.stop
    >
      <v-select
        v-model="departmentsValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        multiple
        placeholder="Select an option"
        :options="departmentOptions"
        :clearable="false"
        :reduce="name => name._id"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Users"
      @click.native.stop
    >
      <v-select
        v-model="usersValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        multiple
        placeholder="Select an option"
        :options="userOptions"
        :clearable="false"
        :reduce="name => name._id"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group label="Status">
      <b-form-radio-group
        v-model="statusFilterValue"
        :options="statusOptions"
        name="status-radios-stacked"
        stacked
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Created Date"
    >
      <b-input-group>
        <flat-pickr
          v-model="createdAtValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    vSelect,
    flatPickr,
  },
  props: {
    statusFilter: {
      type: [String, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    departments: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    users: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    createdAtFilter: {
      type: [String, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      createdAtValue: this.createdAtFilter,
      statusFilterValue: this.statusFilter,
      departmentsValue: JSON.parse(JSON.stringify(this.departments)) || [],
      usersValue: JSON.parse(JSON.stringify(this.users)) || [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    createdAtFilter: {
      immediate: true,
      handler(val) {
        this.createdAtValue = val
      },
    },
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = val || ''
      },
    },
    users: {
      immediate: true,
      handler(val) {
        this.usersValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    departments: {
      immediate: true,
      handler(val) {
        this.departmentsValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:createdAtFilter', this.createdAtValue)
      this.$emit('update:statusFilter', this.statusFilterValue)
      this.$emit('update:users', this.usersValue)
      this.$emit('update:departments', this.departmentsValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.createdAtValue = ''
      this.statusFilterValue = ''
      this.usersValue = []
      this.departmentsValue = []
      this.$emit('update:createdAtFilter', this.createdAtValue)
      this.$emit('update:statusFilter', this.statusFilterValue)
      this.$emit('update:users', this.usersValue)
      this.$emit('update:departments', this.departmentsValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
