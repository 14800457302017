<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="6"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Dashboard Views
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        cols="6"
      >
        <b-button
          v-if="canViewThisAction('create', 'Dashboard')"
          variant="success"
          :to="{ name: 'dashboard-views-create' }"
          class="mobile_create_button"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Dashboard View</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>

              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-block-out-form"
                ref="filter_dropdown_user"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <dashboard-filter
                  :status-filter.sync="statusFilter"
                  :created-at-filter.sync="createdAtFilter"
                  :departments.sync="departments"
                  :users.sync="users"
                  :status-options="statusRadioOptions"
                  :department-options="departmentOptions"
                  :user-options="userOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

        <div v-if="departments.length || users.length || statusFilter || createdAtFilter">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="departmentVal in departments"
                  :key="departmentVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromDepartmentFilter(departmentVal)"
                  >
                    <span class="text-capitalize">{{ resolveDepartmentName(departmentVal) }}</span>
                  </b-form-tag>
                </li>

                <li
                  v-for="userVal in users"
                  :key="userVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromUserFilter(userVal)"
                  >
                    <span class="text-capitalize">{{ resolveUserName(userVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="createdAtFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="createdAtFilter = ''"
                  >
                    {{ createdAtFilter }}
                  </b-form-tag>
                </li>
                <li
                  v-if="statusFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="statusFilter = null"
                  >
                    <span class="text-capitalize">{{ statusFilter }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refDashboardViewsTable"
        class="position-relative min-height-150"
        :items="fetchDashboardViews"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'Dashboard')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="weight-600  align-middle detail-view-id"
              :to="{ name: 'dashboard-views-show', params: { id: data.item._id } }"
            >
              {{ data.item.name }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.name }}
            </b-button>
          </div>
        </template>

        <template #cell(departments)="data">
          <div class="">
            <p
              v-for="(department, key) in data.item.departments"
              :key="key"
              class="mb-0"
            >
              {{ department.name }}
            </p>
          </div>
        </template>

        <template #cell(users)="data">
          <div class="">
            <p
              v-for="(user, key) in data.item.users"
              :key="key"
              class="mb-0"
            >
              {{ user.name }}
            </p>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'Dashboard') || canViewThisAction('create', 'Dashboard') || canViewThisAction('update', 'Dashboard') || canViewThisAction('delete', 'Dashboard')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('show', 'Dashboard')"
              :to="{ name: 'dashboard-views-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View Dashboard View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('update', 'Dashboard')"
              :to="{ name: 'dashboard-views-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit Dashboard View</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'Dashboard')"
              @click="deleteEntity(data.item._id, data.item.name)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete Dashboard</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('create', 'Dashboard')"
              :to="{ name: 'dashboard-views-create', query: { from: data.item._id } }"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Duplicate Dashboard</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalDashboardViews > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalDashboardViews"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormTag,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import useDashboardList from './useDashboardList'
import dashboardStoreModule from './dashboardStoreModule'
import DashboardFilter from './DashboardFilter.vue'
// import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    // UserListAddNew,
    BFormTag,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    DashboardFilter,
  },
  directives: {
    Ripple,
  },
  props: {
    roleId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      departmentOptions: [],
      userOptions: [],
      statusRadioOptions: [
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
      ],
    }
  },

  beforeMount() {
    this.$http.get('user/dashboard-views/respond-with/extra-fields')
      .then(response => {
        this.userOptions = response.data.users ?? []
        this.departmentOptions = response.data.departments ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_user.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_user.show(true)
    },
    removeFromDepartmentFilter(item) {
      const index = this.departments.indexOf(item)
      if (index > -1) {
        this.departments.splice(index, 1)
      }
    },
    removeFromUserFilter(item) {
      const index = this.users.indexOf(item)
      if (index > -1) {
        this.users.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.users = []
      this.departments = []
      this.statusFilter = null
      this.createdAtFilter = null
    },
    resolveDepartmentName(id) {
      const dept = this.departmentOptions.find(o => o._id === id)
      if (dept) {
        return dept.name || ''
      }
      return ''
    },
    resolveUserName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name || ''
      }
      return ''
    },
    deleteEntity(id, name) {
      this.$swal({
        title: `Delete Dashboard - ${name}?`,
        html: 'Once the dashboard is deleted, it is permanently deleted. The selected users will <strong>NOT</strong> able to view the dashboard.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/delete.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete the Dashboard',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteDashboardView()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard-views'

    // console.log(props.roleId)

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchDashboardViews,
      tableColumns,
      perPage,
      currentPage,
      totalDashboardViews,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDashboardViewsTable,
      refetchData,

      toDeletedID,
      deleteDashboardView,
      currentDataLength,

      // UI
      resolveStatusVariant,

      // Extra Filters
      statusFilter,
      users,
      createdAtFilter,
      departments,
    } = useDashboardList()

    return {

      // Sidebar

      fetchDashboardViews,
      tableColumns,
      perPage,
      currentPage,
      totalDashboardViews,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDashboardViewsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteDashboardView,
      currentDataLength,

      // Filter
      avatarText,

      // UI
      resolveStatusVariant,

      canViewThisAction,

      statusOptions,

      // Extra Filters
      statusFilter,
      users,
      createdAtFilter,
      departments,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
